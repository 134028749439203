<template>
  <div class="white" >
    <v-container class="grey lighten-3" fluid>
      <v-card min-height="320" flat color="transparent">
        <v-row>
          <v-col style="position: absolute">
            <v-img class="transparent pa-0 mb-n5 leftStart" :class="{slide: showW}" src="../images/WaldMC.png" contain max-height="100"></v-img>
            <v-img class="transparent pa-0 my-n5 rightStart" :class="{slide: showM}" src="../images/WMeisterC.png" contain max-height="100" />
            <v-img class="transparent pa-0 mt-n5 leftStart" :class="{slide: showC}" transition.origin='top center 0' src="../images/WMCamp.png" contain max-height="100"></v-img>
          </v-col>
        </v-row>
      </v-card>

      <v-row justify="center" v-if="campInfo.show==true">
        <v-col lg="8" cols="11" justify="center">
          <v-card class="pa-4 text-h6 text-center" style="background-color: whitesmoke;" cols="5">
            <div v-for="part in campMessageparts" :key="part">
              <span class="font-bold">{{part}}</span>
              <br>
            </div>
            <br>
            <br>
            <v-row class="justify-center pa-0">
              <v-col v-for="btn in campInfo.buttons" :key="btn.text" cols="auto" class="pa-0">
                <v-btn target="_blank" class="text-h6 ma-2" elevation="5" :href="btn.link" :style="'background-color: '+ btn.color" v-if="btn.active==true">
                  {{btn.text}}
                </v-btn>
              </v-col>
            </v-row>
            <br>
            <!-- <Button class="anmeldenButton">Jetzt Anmelden</Button></a> -->
          </v-card>
        </v-col>
      </v-row>
      <br>
      <br>

      <v-row justify="center">
        <v-col lg="8" md="10" cols="12">
          <Carousel interval=8000 :height="500" :elements="campCar"></Carousel>
          <!-- <v-img src="../images/FlyerWMC2021.png" max-width="1000"></v-img> -->
        </v-col>
      </v-row>
      <v-card color="mt-4 grey lighten-3" flat >
        <br>
        <br>
        <br>
        <p data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="center-bottom" data-aos-offset="200" class="text-h4 text-sm-h3 text-center">Waldmeistercamp</p>
        <br>
        <p data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom" data-aos-offset="200" class="my-4 text-h6 text-center">Das sind</p>
        <br>
        <p data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-bottom" data-aos-offset="200" class="my-5 text-h5 text-center">Jungs und Mädels - irgendwo zwischen zweiter und siebter Klasse</p>
        <br>
        <p data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom" data-aos-offset="200" class="my-5 text-h5 text-center">coole Zeltlageraction und chillige Lagerfeuerabende</p>
        <br>
        <p data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-bottom" data-aos-offset="200" class="my-5 text-h5 text-center">Musik  |  Kreativität  |  Ausdauer  |  Spannung</p>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
      </v-card>
      <v-row justify="center" class="mb-4">
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Carousel from '../components/Carousel';

export default {
  name: "Camp",
  components:{
    Carousel
  },
  data (){
    return {
      showW: false,
      showM: false,
      showC: false,
      campCar: Array,
      campInfo: Object
    }
  },
  mounted (){
    // document.title = "LGV Haiterbach - Waldmeistercamp";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    
    var that = this;
    setTimeout(function() {
        that.showW = true;
      }, 10);
      
    setTimeout(function() {
        that.showM = true;
      }, 200);
      
    setTimeout(function() {
        that.showC = true;
      }, 400);
  
    var xhr = new XMLHttpRequest();
    var home = this;
    xhr.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        home.setCampCar(xhr.responseText);

        // Daten zum Camp anfragen: json um Anmeldung anzuzeigen und andere Infos:
        xhr.onreadystatechange = function() {
          if (this.readyState == 4 && this.status == 200) {
            home.setCampInfo(xhr.responseText);
          }
        };
        xhr.open("POST", '/php/ServerOperations.php', true);
        xhr.send("getcampinfo");
      }
    };
    xhr.open("POST", '/php/ServerOperations.php', true);
    xhr.send("getcamp");

    // this.campInfo = new Object()
    // this.campInfo.show = true;
    // this.campInfo.content = "Hello World, was geht ab bei euch?\r\nIch kann das nur zu gut verstehen\r\n\r\nUnd was machen wir heute so?";
    // this.campInfo.buttonActive = true;
    // this.campInfo.buttonLink = "www.google.de";
    // this.campInfo.buttonText = "Los gehts";
  },
  methods:{
    setCampCar: function(jsonArray){
      var picNames = JSON.parse(jsonArray);
      if (picNames){
        var newPics = new Array();
        for(var i = 0; i < picNames.length; i++){
          newPics.push("../bildchen/" + picNames[i]);
        }
        this.campCar = newPics;
      }
    },
    setCampInfo: function(campInfoJson){
      var info = JSON.parse(campInfoJson);
      this.campInfo = info;
    }
  },
  computed:{
    istOutDated(){
      return Date.now() > new Date(2022, 6, 7, 0, 0, 0);
    },
    campMessageparts(){
      if (this.campInfo.content && this.campInfo.content != ''){
        return this.campInfo.content.split("\n");
      }
      return new Array();
    }
  }
}
</script>

<style>
.anmeldenButton{
  text-align: center;
  margin: auto;
  display: flex;
  border-style: solid;
  border-color: #0e622e;
  padding: 10px;
}
.leftStart{
  left: -100%;
  transition: left 0.5s;
}
.rightStart{
  left: 100%;
  transition: left 0.5s;
}

.slide{
  left: 0%
}
</style>