<template>
  <div class="white" >
    <v-container class="grey lighten-3">
      <br>


        <v-col cols="auto" class="d-flex flex-column align-items-center">
        <v-btn class="customButton" color="primary" @click="goToLink('https://www.paypal.com/donate?hosted_button_id=DRH2Y3UVTFNMY')"> <table border="0" cellpadding="10" cellspacing="0" align="center"><tbody><tr><td align="center"></td></tr><tr><td align="center"><a href="https://ad.doubleclick.net/ddm/trackclk/N426203.3410571DEPAYPALLOGOCENTE/B21619412.227908863;dc_trk_aid=425874235;dc_trk_cid=105298277;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=" title="So funktioniert PayPal" onclick="javascript:window.open('https://ad.doubleclick.net/ddm/trackclk/N426203.3410571DEPAYPALLOGOCENTE/B21619412.227908863;dc_trk_aid=425874235;dc_trk_cid=105298277;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=715, height=539); return false;"><img src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-100px.png" border="0" alt="PayPal Logo" /></a></td></tr></tbody></table> </v-btn>
        <v-btn class="customButton" color="primary" @click="goToLink('https://open.spotify.com/show/3yAYC6TnTwckQhTVbW6FES?si=aa5341c94af143b5')"><v-icon color="blue darken-4">mdi mdi-spotify</v-icon></v-btn>
        <v-btn class="customButton" color="primary" @click="goToLink('https://www.instagram.com/liebenzellergemeinschaft_htb?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==')"><v-icon color="blue darken-4">mdi-instagram</v-icon></v-btn>
      </v-col>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "Camp",
  components:{
    // Carousel
  },
  data (){
    return {
      showW: false,
      showM: false,
      showC: false,
    }
  },
  mounted (){
    // document.title = "LGV Haiterbach - Waldmeistercamp";
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    
    var that = this;
    setTimeout(function() {
        that.showW = true;
      }, 10);
      
    setTimeout(function() {
        that.showM = true;
      }, 200);
      
    setTimeout(function() {
        that.showC = true;
      }, 400);
  
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/php/ServerOperations.php', true);
    xhr.send("getcamp");
  },
  methods:{
    goToLink(url) {
      window.open(url, "_blank");
    },
  },
  computed:{
    istOutDated(){
      return Date.now() > new Date(2022, 6, 7, 0, 0, 0);
    }
  }
}
</script>

<style>
.leftStart{
  left: -100%;
  transition: left 0.5s;
}
.rightStart{
  left: 100%;
  transition: left 0.5s;
}

.slide{
  left: 0%
}

.customButton{
  margin-left : auto;
  margin-right : auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 400px;
  display: flex;
  justify-content: center;

}
</style>